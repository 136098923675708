import { computed, Ref } from 'vue';
import {
  ActiveTableColumn,
  ActiveTableColumnFormat,
  ActiveTableColumnStick,
} from '@/components/activeTable/useActiveTable';
import { ProductionType } from '@/hooks/useConstructor';
import { Debtor, DebtorFields } from '@/hooks/useDebtors';
import { CompanyModuleType, DebtorQuery } from '@/pages/debtors/_module/useDebtorsActions';
import { useLocalI18n } from '@/hooks/useLocalI18n';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';
import { SettingEnabledKey, useSettingEnabled } from '@/hooks/ais/useSettingEnabled';

export function useColumns(
  module: Ref<ProductionType>,
  employees: Ref<{ id: number; name: string }[]>,
) {
  const {
    companyModuleType, companyId,
  } = useProtectedDefaultCompany();
  const { t: columnT } = useLocalI18n(`debtors.column.company_module_type_${companyModuleType.value}`);
  const dateWidth = 220;
  const pikEnabled = useSettingEnabled(companyId, SettingEnabledKey.pik);
  const borEnabled = useSettingEnabled(companyId, SettingEnabledKey.bor);

  const columns = computed<Array<ActiveTableColumn<DebtorQuery>>>(() => (([
    {
      key: 'status',
      field: 'debtor.debtor_status.0.status',
      width: 'minmax(200px, 200px)',
      isRequired: true,
      allowEmpty: true,
    },
    {
      key: 'personal_account',
      field: 'debtor.personal_account',
      isSortable: true,
      width: 'minmax(180px, 180px)',
      isRequired: true,
      stick: ActiveTableColumnStick.left,
    },
    module.value === ProductionType.pretrial && {
      key: 'phone_numbers',
      field: 'phone_numbers',
      width: 200,
      // format: ActiveTableColumnFormat.phone,
    },
    {
      key: 'full_name',
      field: companyModuleType.value === CompanyModuleType.Communal
        ? 'debtor_main_profile.full_name'
        : 'debtor_main_profile.organization.name_short',
      isSortable: true,
      width: 320,
    },
    module.value === ProductionType.executive && {
      key: 'send_to_fssp_date',
      field: 'debtor_main_profile.send_to_fssp_date',
      isSortable: true,
      format: ActiveTableColumnFormat.date,
      label: 'Дата отправки в ФССП',
      width: 180,
    },
    {
      key: 'birth_date',
      field: 'birth_date',
      isSortable: true,
      format: ActiveTableColumnFormat.date,
      label: 'Дата рождения',
      width: 140,
    },
    {
      key: 'register_compilation_date',
      field: 'register_compilation_date',
      isSortable: true,
      format: ActiveTableColumnFormat.date,
      width: 240,
    },
    {
      key: 'last_sync_date',
      field: 'last_sync_date',
      isSortable: true,
      format: ActiveTableColumnFormat.dateTime,
      width: '170px',
    },
    (module.value === ProductionType.judicial || module.value === ProductionType.executive) && {
      key: 'court_place',
      field: 'court_place',
      isSortable: false,
      width: 320,
    },
    module.value === ProductionType.executive && {
      key: 'claimant_full_name',
      field: 'writ_of_execution.claimant_full_name',
      isSortable: false,
      width: 320,
      label: 'Взыскатель',
    },
    module.value === ProductionType.judicial && {
      key: 'filing_date',
      field: 'filing_date',
      isSortable: true,
      format: ActiveTableColumnFormat.date,
      width: dateWidth,
    },
    {
      key: 'status_updated_at',
      field: 'status_updated_at',
      format: ActiveTableColumnFormat.date,
      isSortable: true,
      width: dateWidth,
    },
    {
      key: 'attached_employee',
      field: 'attached_employee',
      format: ActiveTableColumnFormat.string,
      isSortable: false,
      width: 220,
      getValue: (record: DebtorFields) => {
        const items = record.debtor?.assigned_users?.map((e) => employees.value
          .find((u) => u.id === e.user)?.name || '');
        const count = items.length;
        const firstTwo = items.slice(0, 2);
        return firstTwo.join(', ') + (count > 2 ? ` + ${count - 2}` : '');
      },
    },
    {
      key: 'address',
      field: 'debtor_main_profile.address',
      width: 400,
    },
    {
      key: 'okrug_short',
      field: 'bailiff_region',
      width: 120,
    },
    module.value === ProductionType.executive && {
      key: 'case_number',
      field: 'writ_of_execution.case_number',
      width: 281,
    },
    module.value === ProductionType.executive && {
      key: 'serial_number',
      field: 'writ_of_execution.serial_number',
      width: 281,
    },
    module.value === ProductionType.executive && {
      key: 'end_date',
      field: 'writ_of_execution.end_date',
      format: ActiveTableColumnFormat.date,
      width: 281,
    },
    module.value === ProductionType.executive && {
      key: 'bailiff_full_name',
      field: 'writ_of_execution.bailiff_full_name',
      width: 281,
    },
    {
      key: 'oc',
      field: 'oc',
      label: 'ОС',
    },
    [ProductionType.judicial].includes(module.value) && {
      key: 'last_active_court_case_history_sync_date',
      field: 'last_active_court_case_history_sync_date',
      format: ActiveTableColumnFormat.dateTime,
      width: 280,
    },
    [ProductionType.judicial].includes(module.value) && {
      key: 'last_archive_court_case_history_sync_date',
      field: 'last_archive_court_case_history_sync_date',
      format: ActiveTableColumnFormat.dateTime,
      width: 290,
    },
    [ProductionType.pretrial, ProductionType.judicial].includes(module.value) && {
      key: 'total_accrual',
      field: 'total_accrual',
      isSortable: true,
      width: 'minmax(80px, 200fr)',
      format: ActiveTableColumnFormat.money,
    },
    {
      key: 'total_paid_up',
      field: 'total_paid_up',
      isSortable: true,
      width: 'minmax(80px, 200fr)',
      format: ActiveTableColumnFormat.money,
    },
    [ProductionType.pretrial, ProductionType.judicial].includes(module.value) && {
      key: 'total_debt',
      field: 'total_debt',
      isSortable: true,
      width: 'minmax(80px, 200fr)',
      format: ActiveTableColumnFormat.money,
    },
    [ProductionType.pretrial, ProductionType.judicial].includes(module.value) && {
      key: 'total_penalty',
      field: 'total_penalty',
      isSortable: true,
      width: 'minmax(80px, 200fr)',
      format: ActiveTableColumnFormat.money,
    },
    [ProductionType.pretrial, ProductionType.judicial].includes(module.value)
    && (borEnabled.value || pikEnabled.value) && {
      key: 'total_actual_debt',
      field: 'actual_debt',
      isSortable: true,
      getValue: (record: Debtor) => +record.actual_debt,
      width: 'minmax(100px, 200fr)',
      format: ActiveTableColumnFormat.money,
    },
    module.value === ProductionType.executive && {
      key: 'total_debt_entrepreneur',
      field: 'writ_of_execution.amount',
      width: 'minmax(80px, 200fr)',
      getValue: (record: Debtor) => (record.total_debt || record.writ_of_execution?.amount),
      format: ActiveTableColumnFormat.money,
    },
    module.value === ProductionType.judicial && {
      key: 'total_fee',
      field: 'total_fee',
      isSortable: true,
      width: 'minmax(160px, 200fr)',
      format: ActiveTableColumnFormat.money,
    },
    {
      key: 'total_total_debt',
      field: 'total_total_debt',
      width: 'minmax(80px, 200fr)',
      format: ActiveTableColumnFormat.money,
      stick: ActiveTableColumnStick.right,
    },
  ] as Array<ActiveTableColumn<DebtorQuery> | boolean>)
    .filter(Boolean) as Array<ActiveTableColumn<DebtorQuery>>).map((column) => ({
    ...column,
    ...{
      width: column.width,
      stick: column.stick,
      label: column.label ?? columnT(`${column.key || column.field}`),
    },
  })));

  return columns;

}
