import { useLocalI18n } from '@/hooks/useLocalI18n';
import {
  computed, onBeforeUnmount, Ref, ref, watch, watchEffect,
} from 'vue';
import { Debtor, useDebtors } from '@/hooks/useDebtors';
import { useDefaultCompany } from '@/hooks/useDefaultCompany';
import { useEmployees } from '@/hooks/useEmployees';
import { useRoute } from 'vue-router';
import { useProtectedInject } from '@/hooks/useProtectedInject';
import { RouterKey } from '@core/symbols';
import { DebtorQuery, useDebtorsActions } from '@/pages/debtors/_module/useDebtorsActions';
import { useUserPrefs } from '@/hooks/useUserPrefs';
import { useInitQueryTableParams } from '@/hooks/useInitQueryTableParams';
import { useQuery } from 'vue-query';
import { useActiveTable } from '@/components/activeTable/useActiveTable';
import {
  mapDebtorsQueryModelToRequestFilters,
} from '@/pages/debtors/_module/mapDebtorsQueryModelToRequestFilters';
import { useFetchWithHidePreloader } from '@/hooks/useFetchWithHidePreloader';
import { ApiCommand, OrderDirection } from '@/store/modules/api';
import {
  useSubscribeDebtorStatusUpdate,
} from '@/pages/debtors/_module/useSubscribeDebtorStatusUpdate';
import { DebtorStatusPayload, IDialogComponent, useDialog } from '@/hooks/useDialog';
import { useDebtorNavigation } from '@/pages/debtors/_module/useDebtorNavitagion';
import { SignalType, useSignal } from '@/hooks/useSignal';
import { formatPhone } from '@/utils/string';
import { ProductionType } from '@/hooks/useConstructor';
import { commonLegacyApiRequest } from '@core/service/commonService';
import { useColumns } from '@/pages/debtors/_module/useColumns';
import { useDebtorFilters } from '@/pages/debtors/_module/filters/useDebtorFilters';
import { SettingEnabledKey, useSettingEnabled } from '@/hooks/ais/useSettingEnabled';
import { useProtectedDefaultCompany } from '@/hooks/useProtectedDefaultCompany';

export function useDebtorsPage(module: Ref<ProductionType>) {
  const { t } = useLocalI18n('debtors');
  const { fetchDebtors } = useDebtors();
  const { companyModuleType } = useDefaultCompany();
  const { companyId } = useProtectedDefaultCompany();
  const { fetchEmployees } = useEmployees();
  const currentRoute = useRoute();
  const router = useProtectedInject(RouterKey);
  const activeTableRef = ref<any>(null);
  const employees = ref<{ id: number; name: string }[]>([]);
  const { actions: actionsDefs } = useDebtorsActions(module);
  const pikEnabled = useSettingEnabled(companyId, SettingEnabledKey.pik);
  const borEnabled = useSettingEnabled(companyId, SettingEnabledKey.bor);

  const {
    userPrefs,
    updateUserPrefs,
  } = useUserPrefs();
  const moduleUserPrefs = computed(
    () => userPrefs.value.interface_preferences[module.value]?.actions ?? [],
  );
  const defaultVisibleColumns = computed(
    () => userPrefs.value.interface_preferences[module.value]?.visible_columns ?? [],
  );
  const defaultFilters = computed(
    () => userPrefs.value.interface_preferences[module.value]?.default_debtors_table_filters || null,
  );

  const debtorsTableInitQueryParams = useInitQueryTableParams<DebtorQuery>();

  const { data: employeesRaw } = useQuery(['Employees'], () => fetchEmployees({
    filters: {
      companyId: companyId.value,
    },
    limit: 1000,
    page: 1,
  }), { refetchOnWindowFocus: false });

  watch(() => employeesRaw.value, (val) => {
    if (val?.status) {
      employees.value = val?.response?.results.map((u) => ({
        name: `${u.first_name} ${u.last_name}`,
        id: u.id,
      }));
    }
  });

  let currentFilter = '';
  let currentTotal: Record<string, number> = {};
  const columnsDefs = useColumns(module, employees);
  const filtersDefs = useDebtorFilters(module);
  const summariesDefs = computed(() => (
    [
      'total_accrual',
      'total_paid_up',
      'total_debt',
      'total_total_debt',
      'total_penalty',
      module.value === ProductionType.judicial && 'total_fee',
      module.value === ProductionType.executive && 'total_debt_entrepreneur',
      (module.value !== ProductionType.executive && (pikEnabled.value || borEnabled.value)) && 'total_actual_debt',
    ].filter(Boolean) as Array<keyof Debtor>
  ));

  const {
    fetchData: refetchDebtors,
    records,
    actions,
    page,
    total,
    sort,
    columns,
    filtersModel,
    filters,
    keyField,
    getRecordRenderKey,
    limit,
    defaultLimit,
    summariesFields,
    summaries,
    isLoading,
    loadingInChunks,
    totalChunks,
    currentChunk,
  } = useActiveTable<DebtorQuery, Debtor, 'pk'>({
    keyField: 'debtor.pk' as any,
    name: 'debtors',
    summaries: summariesDefs,
    mapFiltersModelToRequest: mapDebtorsQueryModelToRequestFilters,
    defaultLimit: computed(() => userPrefs.value.debtor_per_page),
    fetch: useFetchWithHidePreloader(async ({
      params,
      signal,
    }) => {
      const { response } = await fetchDebtors({
        ...params,
        filters: {
          ...params.filters,
          status: params.filters.status ?? 'active',
        },
        signal,
      });
      if (JSON.stringify(params.filters) === currentFilter) {
        response.total = currentTotal;
        return response;
      }
      const { response: totals } = await commonLegacyApiRequest({
        command: ApiCommand.fetchDebtorsTotals,
        params: params.filters,
      });
      currentFilter = JSON.stringify(params.filters);
      // @ts-ignore
      currentTotal = Object.entries(totals).reduce((acc, [key, value]) => {
        acc[`total_${key}`] = key === 'debt_entrepreneur'
          // @ts-ignore
          ? (totals.debt_entrepreneur || 0) + (totals.debt || 0)
          : value;
        return acc;
      }, {} as Record<string, number>);
      response.total = currentTotal;
      return response;
    }),
    columns: columnsDefs,
    filters: filtersDefs,
    actions: actionsDefs,
    initFilters: computed(() => {
      if (debtorsTableInitQueryParams.filters.value && Object.values(debtorsTableInitQueryParams.filters.value).length) {
        return debtorsTableInitQueryParams.filters.value;
      }
      return defaultFilters.value;
    }),
    getRecordRenderKey: (d) => `${d.debtor.pk}-${d.production_type}`,
    defaultSort: computed(
      () => debtorsTableInitQueryParams.sort.value || [{
        key: 'full_name',
        direction: OrderDirection.asc,
      }],
    ),
    fetchInChunks: true,
  });

  useSubscribeDebtorStatusUpdate(
    records,
    module,
  );

  watch(filtersModel, (fModel: any) => {
    if (fModel.save_filters_as_default) {
      const filteredModel = Object.fromEntries(
        Object.entries(fModel)
          .filter(([key]) => {
            const isVisible = filters?.value?.find((field) => field.key === key)?.isVisible;
            console.log('field is visible', key, isVisible);
            return isVisible === undefined || isVisible === true;
          }),
      );
      console.log('filteredModel', filteredModel);
      updateUserPrefs({
        interface_preferences: {
          [module.value]: {
            default_debtors_table_filters: filteredModel,
          },
        },
      });
    } else {
      updateUserPrefs({
        interface_preferences: {
          [module.value]: {
            default_debtors_table_filters: null,
          },
        },
      });
    }
  });

  if (page.value) {
    page.value = debtorsTableInitQueryParams.page.value ?? 1;
  }
  watch(page, (page) => {
    return router.replace({
      ...currentRoute,
      query: {
        ...(currentRoute.query || {}),
        page,
      },
    });
  });
  const filtersSerialized = computed(() => JSON.stringify(filtersModel.value));
  watch(filtersSerialized, async (filters, oldFilters) => {
    if (filters === oldFilters) {
      return;
    }
    console.log('update debtors filters');
    if (filters) {
      const filtersModel = JSON.parse(filters);
      const filtersString = JSON.stringify(Object.fromEntries(
        Object.entries(filtersModel)
          .filter(
            ([k, v]) => {
              if (v === null || v === undefined) return false;
              const defaultValue = filtersDefs.value.find((f) => f.key === k)?.defaultValue;
              if (Array.isArray(defaultValue) && defaultValue.length === 0) return true;
              if (defaultValue !== null && defaultValue !== undefined) return false;
              return true;
            },
          ),
      ));
      const oldFiltersModel = JSON.parse(oldFilters);
      const oldFiltersString = JSON.stringify(Object.fromEntries(
        Object.entries(oldFiltersModel)
          .filter(
            ([k, v]) => {
              if (v === null || v === undefined) return false;
              const defaultValue = filtersDefs.value.find((f) => f.key === k)?.defaultValue;
              if (Array.isArray(defaultValue) && defaultValue.length === 0) return true;
              if (defaultValue !== null && defaultValue !== undefined) return false;
              return true;
            },
          ),
      ));
      if (filtersString === oldFiltersString) {
        return;
      }
      if (filtersString !== '{}') {
        await router.replace({
          ...currentRoute,
          query: {
            ...(currentRoute.query || {}),
            filters: filtersString,
          },
        });
      } else {
        const {
          filters,
          ...query
        } = currentRoute.query || {};
        await router.replace({
          ...currentRoute,
          query,
        });
      }
    }
  });
  watch(computed(() => JSON.stringify(sort.value)), async (sortStr, oldSortStr) => {
    if (sortStr !== oldSortStr) {
      await router.replace({
        ...currentRoute,
        query: {
          ...(currentRoute.query || {}),
          sort: sortStr,
        },
      });
    }
  });

  watch(module, () => {
    records.value = [];
    summaries.value = {};
  });

  const {
    showDialog,
  } = useDialog();

  const { onRowClick } = useDebtorNavigation(
    module,
    records,
    page,
    limit,
    filtersModel,
    sort,
    total,
  );

  const {
    subscribeToSignal,
    dispatchSignal,
  } = useSignal();

  onBeforeUnmount(
    subscribeToSignal(SignalType.getDebtorFilters, async () => {
      await dispatchSignal(
        SignalType.debtorFilters, mapDebtorsQueryModelToRequestFilters(filtersModel.value),
      );
    }),
  );
  const showDebtorStatusDialog = async ({
    selectedItemIdList,
  }: { selectedItemIdList: Array<Debtor['pk']> }) => {
    await showDialog({
      component: IDialogComponent.debtorStatus,
      addInRoute: false,
      payload: {
        productionType: module.value as DebtorStatusPayload['productionType'],
        debtorIds: selectedItemIdList,
      },
    });
  };

  onBeforeUnmount(
    subscribeToSignal(SignalType.debtorsUpdated, () => {
      // @ts-ignore
      activeTableRef.value?.resetSelection();
      return refetchDebtors();
    }),
  );

  const onClickDebtorStatus = (debtorId: number) => {
    return showDebtorStatusDialog({
      selectedItemIdList: activeTableRef.value.allOnAllPagesSelected
        ? []
        : [...(activeTableRef.value.selectedItemIdList.length ? activeTableRef.value.selectedItemIdList : [debtorId])],
    });
  };
  return {
    t,
    records,
    actions,
    page,
    total,
    sort,
    columns,
    filtersModel,
    filters,
    keyField,
    getRecordRenderKey,
    limit,
    defaultLimit,
    employees,
    onRowClick,
    showDebtorStatusDialog,
    summaries,
    summariesFields,
    moduleUserPrefs,
    defaultVisibleColumns,
    activeTableRef,
    companyModuleType,
    formatPhone,
    isLoading,
    loadingInChunks,
    totalChunks,
    currentChunk,
    onClickDebtorStatus,
  };
}
